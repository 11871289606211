<template>
  <v-autocomplete v-model.number="value" :items="options" :label="label" outlined dense hide-details @change="onChange"></v-autocomplete>
</template>

<script>
import {debounce} from "lodash/function";
import {httpClient} from "@/libs/http";

export default {
  name: "SelectWard",
  props: {
    name: {
      type: String,
      default: () => ''
    },
    idItem: {
      type: [Number, String],
      default: () => null
    },
    idCity: {
      type: [String, Number],
      default: () => null
    },
    idCounty: {
      type: [String, Number],
      default: () => null
    },
    idWard: {
      type: [String, Number],
      default: () => null
    },
    label: {
      type: String,
      default: function () {
        return this.$t("labels.ward") + ' *'
      }
    }
  },
  data: () => ({
    value: null,
    options: []
  }),
  computed: {

  },
  watch: {
    value() {
      this.onChange()
    },
    idCounty() {
      this.getList()
    }
  },
  mounted() {
    if (this.idCounty) {
      this.getList()
    }
  },
  methods: {
    onChange: debounce(function () {
      this.$emit('onChange', {
        id: this.idItem,
        name: this.name,
        value: this.value
      })
    }, 100),
    async getList() {
      this.options = []
      this.value = null
      const {data} = await httpClient.post('/get-ward', {id_county: this.idCounty})
      this.options = data.map(e => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || ''
      }))
      if (this.idWard) {
        const checkVal = this.options.find(o => o.value === this.idWard)
        if (checkVal) {
          this.value = this.idWard
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
